/* // IOTOPS-T48 ADD THIS ALL CSS */

.consumption-chart-modal > .modal-dialog{
    min-width: 90vw ;
}
.ant-picker-dropdown{
    z-index: 2000 ;
}

.ant-btn-primary{
    background-color: #4096ff;
}