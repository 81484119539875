.cron_builder{
    max-width: 69% !important;
}

.cron-builder-bg{
    background-color: #919ae7  !important;
}


@keyframes fadeInRightGrow {
    from {
        width: 100%;
        /* -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0); */
    }

    to {
        width: 66.666667%
            /* -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0); */
    }
}

.cron-animate__fadeInRight_grow {
    /* -webkit-animation-name: fadeInRight; */
    animation: fadeInRightGrow 0.5s;
}

/* #tooltip {
    background: rgb(255, 251, 251);
    color: rgb(0, 0, 0);
    padding: 4px 8px;
    font-size: 13px;
    border-radius: 4px;
}

#tooltip.hidden {
    visibility: hidden;
    pointer-events: none;
} */

#arrow,
#arrow::before {
    position: absolute;
    width: 12px;
    height: 12px;
    /* z-index: -1; */
}

#arrow::before {
    content: " ";
    background-color: white;
    box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0px;
    left: 0;
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
}

#tooltip[data-popper-placement^="top"]>#arrow {
    bottom: -4px;
}

#tooltip[data-popper-placement^="bottom"]>#arrow {
    top: -4px;
}

#tooltip[data-popper-placement^="left"]>#arrow {
    right: -4px;
}

#tooltip[data-popper-placement^="right"]>#arrow {
    left: -4px;
}

#tooltip[data-popper-reference-hidden="true"],
#tooltip[data-popper-escaped="true"] {
    visibility: hidden;
    pointer-events: none;
}

.Toastify__toast-container {
    top: -0.5rem;
    width: max-content;
    max-width: 420px;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
    background: #F46558;
    cursor: default;
    padding-bottom: 0px;
    padding-top: 0px;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
    cursor: default;
    padding-bottom: 0px;
    padding-top: 0px;
}

.Toastify__close-button {
    padding: 5px;
}

.Toastify__toast-icon {
    width: 15px;
}

.ant-popover-placement-bottomRight .ant-popover-arrow {
    right: 75px !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
    cursor: default;
    padding-bottom: 0px;
    padding-top: 0px;
}


@keyframes fadeInRight {
    from {
        width: 0;
        /* -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0); */
    }

    to {
        width: 33.33333333%;
        /* -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0); */
    }
}

.animate__fadeInRight {
    /* -webkit-animation-name: fadeInRight; */
    animation: fadeInRight 0.5s;
}

@keyframes fadeInLeft {
    from {
        /* visibility: visible; */
        flex-grow: 0;
        /* transform: translate3d(-30%, 0, 0); */
    }

    to {
        flex-grow: 1;
        /* transform: translate3d(0, 0, 0); */
    }
}

.animate__fadeInLeft {
    animation: fadeInLeft 0.75s;
}

@keyframes fadeInRightGrow {
    from {
        width: 100%;
        /* -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0); */
    }

    to {
        width: 66.666667%
            /* -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0); */
    }
}

.animate__fadeInRight_grow {
    /* -webkit-animation-name: fadeInRight; */
    animation: fadeInRightGrow 0.5s;
}

@keyframes fadeOutRight {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

.animate__fadeOutRight {
    animation: fadeOutRight 0.5s;
}

.show-read-more-name {
    max-width: 30ch;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* activity */

.activity-log-container {
    width: 100%;
    max-width: 1000px;
    margin: 4px auto;
    padding: 4px 16px;
}

.timeline {
    border-left: 1px solid #d4d4d8;
    width: 100%;
    position: relative
}

.timeline:before {
    content: "";
    position: absolute;
    left: -6px;
    top: 0;
    width: 10px;
    height: 10px;
    background-color: #d4d4d8;
    border-radius: 50%;
}

.date {
    font-weight: 600;
    color: #333;
    padding: 0 5px 0 20px;
}

.date-section {
    /* margin-bottom: 16px; */
    position: relative;
    display: inline-flex;
    border: 1px solid #d4d4d8;
    border-radius: 0 20px 20px 0;
    text-align: left;
    padding: 2px;
    left: -7px;
    font-size: 14px;
    min-height: 14px;
    min-width: 14px;
    background-color: #fff;
    margin-top: 20px;
}

.date-section:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -3px;
    width: 8px;
    background-color: #fff
}

.date-section:after {
    content: '';
    background-color: #1b8b4f;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    top: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 1;
    margin: auto
}

.timeline-item {
    padding: 12px 0;
    margin-bottom: 12px;
    display: flex;
}

.timeline-icon {
    background-color: #6366f1;
    width: 22px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -11px;
    border-radius: 50%;
    font-size: 12px;
    color: white;
    font-weight: 600;
    align-self: flex-start;
}

.activity-log-avatar {
    background-color: #cbd5e1;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #596577;
    margin-right: 10px;
}

.timeline-content {
    padding: 2px 4px;
    border-radius: 8px;
    flex: 1;
}

.timeline-data {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0px 16px;
    border-bottom: 1px solid #e2e2e2;

}

.activity-log-user {
    display: flex;
    align-items: center;
    gap: 8px;
}

.activity-log-date {
    color: #888;
    font-size: 14px;
    margin: 4px 0;
}

.activity-log-activity {
    font-weight: 500;
    color: #333;
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
}

.activity-log-activity-creator {
    margin: 0;
    color: #333;
}

.activity-log-activity-type {
    margin: 0;
    color: #6366f1;
}

.activity-log-activity-static {
    margin: 0;
    color: #333;
}

.activity-log-activity-column {
    margin: 0;
    color: #292929;
    font-weight: 700;
}


.activity-log-changes {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 8px;
}

.activity-log-changes>div {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
}

.activity-log-changes>div>p {
    font-weight: 700;
    color: #292929;
    margin-bottom: 2px;
}

.activity-log-changes>div>pre {
    background: #f7f7f7;
    padding: 1px 6px;
    border-radius: 4px;
    white-space: pre-wrap;
    border: 1px solid #e2e2e2;
    font-size: 12px;
    margin-bottom: 4px;
}

.activity-log-changes>div>span.arrow {
    margin: 0 10px;
    color: #555;
    font-size: 18px;
    align-self: center;
}


.arrow {
    margin: 0 10px;
    color: #555;
    font-size: 18px;
}

pre {
    background: #f7f7f7;
    padding: 4px;
    border-radius: 4px;
    white-space: pre-wrap;
    border: 1px solid #e2e2e2;
    font-size: 12px;
}

.error-message {
    color: red;
    font-weight: 600;
}

.timeline-card{
    margin-left: 40px;
}
.fade.tab-pane{
    opacity: 999 !important;
}